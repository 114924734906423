import { useRouter } from 'next/router'
import Script from 'next/script'
import { memo, useEffect } from 'react'
import { GA4_GROUP } from '@/data/constants'
import { trackPageView } from '@/services/Tracking/GTAG/trackPageView'
import { configAnalytics } from '@/services/Tracking/GTAG/configAnalytics'

interface GoogleAnalyticsProps{
  googleAnalyticsId: string
}

const PDP_REGEX = /^\/p-[a-z0-9-]+-\d+/
const getCleanPath = (
  regex: RegExp,
) => (
  pathAndParams: string,
) => (pathAndParams.match(regex)?.[0]?.toLowerCase().trim() ?? pathAndParams)

const isTheSamePath = (
  firstPathAndParams: string,
  secondPathAndParams: string,
) => (
  formatter: (regex: RegExp) => (url: string) => string,
) => {
  const regexFormatter = formatter(PDP_REGEX)
  const firstPath = regexFormatter(firstPathAndParams)
  const secondPath = regexFormatter(secondPathAndParams)
  return firstPath === secondPath
}

export const isRunPageView = (
  firstFullPath:string,
  secondFullPath:string,
) => !PDP_REGEX.test(firstFullPath) || !isTheSamePath(firstFullPath, secondFullPath)(getCleanPath)

const GoogleAnalytics: React.FC<GoogleAnalyticsProps> = ({ googleAnalyticsId }) => {
  const router = useRouter()
  // Send page views when users gets to the landing page
  useEffect(() => {
    if (!googleAnalyticsId || router.isPreview) {
      return
    }
    configAnalytics(
      googleAnalyticsId,
      {
        groups: GA4_GROUP, // manually send page views to have full control
        send_page_view: false,
      },
    )

    trackPageView(window.location.pathname, GA4_GROUP)
    // eslint-disable-next-line
  }, [])

  // Send page views on route change
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (!googleAnalyticsId || router.isPreview) {
        return
      }

      if (isRunPageView(url, router.asPath)) {
        trackPageView(url, GA4_GROUP)
      }
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('hashChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('hashChangeComplete', handleRouteChange)
    }
  }, [googleAnalyticsId, router.asPath, router.events, router.isPreview])

  if (!googleAnalyticsId || router.isPreview) {
    return null
  }

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
      />
      <Script
        id="gtag-init"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          `,
        }}
      />
    </>
  )
}
export default memo(GoogleAnalytics)
