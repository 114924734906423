import { GA_TRACKING_ID } from '@/services/Configuration'
import { FailedStatus, SuccessfulStatus } from '@/services/Tracking/GTAG/TrackStatuses'

interface AnalyticsConfigParams extends Gtag.ConfigParams, Gtag.ControlParams{
}

export const configAnalytics = (
  analyticsId: string,
  eventConfigParams: AnalyticsConfigParams,
): boolean => {
  if (!!window?.gtag && GA_TRACKING_ID) {
    window.gtag('config', analyticsId, eventConfigParams)
    return SuccessfulStatus
  }
  return FailedStatus
}

export default configAnalytics
